import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '**',
    redirectTo: ''
  },
  {
    path: 'sms-historial',
    loadChildren: () => import('./sms-historial/sms-historial.module').then( m => m.SmsHistorialPageModule)
  },
  {
    path: 'consumos-historial',
    loadChildren: () => import('./consumos-historial/consumos-historial.module').then( m => m.ConsumosHistorialPageModule)
  },
  {
    path: 'logs',
    loadChildren: () => import('./logs/logs.module').then( m => m.LogsPageModule)
  },
  {
    path: 'sims',
    loadChildren: () => import('./sims/sims.module').then( m => m.SimsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
